import React, { useEffect, useState } from 'react'
import { styled } from 'linaria/react'
import SiteButton from '../SiteButton'
import { NavArrowRight } from 'iconoir-react'
import Image from '@jetshop/ui/Image'
import Link from '../Link'
import { Above } from '@jetshop/ui/Breakpoints'

const PictureBannerContainer = styled.div`
   position: relative;
   background-color: black;
   height: calc(50vh - 72px);
   min-height: 25rem;
   width: 100%;
   overflow: hidden;

   .title {
      font-weight: 700;
      text-shadow: 0 0 2rem #000;
   }

   .text {
      font-size: 18px;
      font-family: var(--font-grotesk);
      text-shadow: 0 0 1.5rem #000;
      font-weight: 500;
   }

   .background-content {
      position: relative;
      z-index: 2;
   }

   .background-overlay {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: linear-gradient(
         180deg,
         rgba(228, 228, 228, 0) 50%,
         rgba(228, 228, 228, 1) 100%
      );
      z-index: 1;
   }

   [data-flight-image] img {
      margin-top: 0px;
      padding: 0px;
   }
`

const pickRandomBannerItem = properties => {
   if (!properties?.children) {
      return null
   }
   const childrenArray = Array.isArray(properties?.children)
      ? properties.children
      : []

   if (childrenArray.length > 0) {
      const randomIndex = Math.floor(Math.random() * childrenArray.length)
      return childrenArray[randomIndex]
   } else {
      return null
   }
}

const PictureBanner = ({ properties }) => {
   const [randomItem, setRandomItem] = useState(null)

   useEffect(() => {
      // Only execute pickRandomBannerItem if prevRandomItem is falsy
      // helps avoid new randomItem on every comp rerender
      setRandomItem(
         prevRandomItem => prevRandomItem || pickRandomBannerItem(properties)
      )
   }, [properties])

   const {
      title,
      subTitle,
      ctaText,
      ctaLink,
      backgroundImage,
      backgroundImageMobile,
      hideGradientOverlay,
   } = randomItem ?? {}

   const getBackgroundImage = matches => {
      if (!matches) {
         // Mobile view: fallback to backgroundImage if mobile-specific image doesn't exist
         return backgroundImageMobile || backgroundImage
      }
      // Desktop view
      return backgroundImage
   }

   return (
      <PictureBannerContainer>
         {randomItem && (
            <Above breakpoint="md">
               {matches =>
                  ctaLink ? (
                     <Link to={ctaLink}>
                        <Image
                           fillAvailableSpace={true}
                           src={getBackgroundImage(matches)}
                           // gravity={'east'}
                           aspect="1:1"
                           critical={true}
                        >
                           {!hideGradientOverlay && (
                              <div className="background-overlay"></div>
                           )}
                           <div className="background-content px-128 h-100">
                              <div className="d-flex h-100 pb-64 align-items-end">
                                 <div className="w-100 text-white">
                                    <h1 className="title">{title}</h1>
                                    <p className="text">{subTitle}</p>
                                    <SiteButton
                                       style={{
                                          padding:
                                             '0.8rem 0.8rem 0.8rem 1.5rem',
                                       }}
                                       href={ctaLink}
                                       type="submit"
                                       styleType="solid"
                                       color="blue"
                                       size="large"
                                       icon={true}
                                    >
                                       {ctaText}
                                       <NavArrowRight
                                          strokeWidth={2.0}
                                          style={{
                                             marginLeft: '1rem',
                                          }}
                                       ></NavArrowRight>
                                    </SiteButton>
                                 </div>
                              </div>
                           </div>
                        </Image>
                     </Link>
                  ) : (
                     <Image
                        fillAvailableSpace={true}
                        src={getBackgroundImage(matches)}
                        // gravity={'east'}
                        aspect="1:1"
                        critical={true}
                     >
                        {!hideGradientOverlay && (
                           <div className="background-overlay"></div>
                        )}
                        <div className="background-content px-128 h-100">
                           <div className="d-flex h-100 pb-64 align-items-end">
                              <div className="w-100 text-white">
                                 <h1 className="title">{title}</h1>
                                 <p className="text">{subTitle}</p>
                                 <SiteButton
                                    style={{
                                       padding: '0.8rem 0.8rem 0.8rem 1.5rem',
                                    }}
                                    href={ctaLink ?? ''}
                                    type="submit"
                                    styleType="solid"
                                    color="blue"
                                    size="large"
                                    icon={true}
                                 >
                                    {ctaText}
                                    <NavArrowRight
                                       strokeWidth={2.0}
                                       style={{ marginLeft: '1rem' }}
                                    ></NavArrowRight>
                                 </SiteButton>
                              </div>
                           </div>
                        </div>
                     </Image>
                  )
               }
            </Above>
         )}
      </PictureBannerContainer>
   )
}

export default PictureBanner
